// let preloaderTime = parseInt( getComputedStyle( document.documentElement ).getPropertyValue( '--preloader-time' ) ) * 1000;
// let preloaderTimeScale = parseInt( getComputedStyle( document.documentElement ).getPropertyValue( '--preloader-time-scale' ) ) * 1000;
// let preloaderDelay = parseInt( getComputedStyle( document.documentElement ).getPropertyValue( '--preloader-delay' ) ) * 1000;

Splitting({
	by: 'chars'
});
function initScrollOut() {
	ScrollOut({
		targets: '[data-splitting], [data-fade], [data-zoom-video]',
		threshold: 0.2,
		once: true,
		onShown: function(element, ctx, scrollingElement) {
			if (element.hasAttribute( 'data-splitting')) {
				const countChar = element.style.getPropertyValue('--char-total');
				const timeChar = 2000;
				let delayChar = 0.05;
				let timeout = 0;
				if (element.hasAttribute( 'split-text')) {
					delayChar = 0.015;
				}
				timeout = (countChar * delayChar + timeChar) * 1000;
				setTimeout(function() {
					element.innerText = element.textContent
				}, timeout);
			}
		}
	});
}
if ( typeof preloader !== 'undefined' && cookie.isPreloader === undefined || cookie.isPreloader === 'true' ) {
	setTimeout(function() {
		initScrollOut();
	}, preloaderDelay + preloaderTime + 500 );
} else {
	initScrollOut();
}

function telMask() {
    $( '[type="tel"]' ).inputmask( '+38 (999) 999 99 99' );
}

function headerFixed(){
    $('.header').toggleClass('fixed', $(window).scrollTop() > 10);
}

function mobMenu(){
    const btn = $('.js-menu');

    btn.click(function () {
        $(this).toggleClass('active');
        // $('body, html').toggleClass('overflow');

    });

    $(document).mouseup(function (e){
        var div = $('.header');
        if (!div.is(e.target)
            && div.has(e.target).length === 0) {
            btn.removeClass('active');
            // $('body, html').removeClass('overflow');
        }
    });
}

function iconSlider() {
	const iconSlider = $('.js-ico-slider');
	if (iconSlider.length > 0) {
		var defaultItems = 5;
		if ($(window).width() < 700) {
			defaultItems = 1;
		}
		iconSlider.each(function () {
			if ($(this).children('.ico-slider-item').length > defaultItems) {
				$(this).slick({
					infinite: true,
					slidesToShow: 6,
					slidesToScroll: 1,
					autoplay: true,
					autoplaySpeed: 1,
					speed: 3000,
					dots: false,
					arrows: false,
					variableWidth: true,
					easing: 'linear',
					cssEase: 'linear',
					centerMode: false,
					pauseOnHover: false,
					pauseOnFocus: false,
					pauseOnDotsHover: false,
					responsive: [
						{
							breakpoint: 700,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 1
							}
						}
					]
				});
			}
		});
	}
}

function toNextSection() {
    $('.js-to-next-section').click(function () {
        const nextSection = $(this).closest('section').next('section');
        if (nextSection.length) {
            $('html,body').animate({ scrollTop: nextSection.offset().top }, 800);
            return false;
        }
    });
}
//
// function formValidation() {
//     const forms = $('form');
//     forms.each(function () {
//         $(this).submit(function(e) {
//             e.preventDefault();
//
//             var form = $(this);
//
//             var textField = form.find('[type="text"]');
//             var textValue = textField.val().trim();
//
//             var textareaField = form.find('textarea');
//             var textareaValue = textareaField.val().trim();
//
//             var emailField = form.find('[type="email"]');
//             var email = emailField.val();
//             var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
//
//             form.find('.form-group').removeClass('has-error'); // Видаляємо клас has-error усіх полів
//
//             if (textValue === '') {
//                 textField.closest('.form-group').addClass('has-error');
//             }
//
//             if (email === '' || !emailPattern.test(email)) {
//                 emailField.closest('.form-group').addClass('has-error');
//             }
//
//             if (textareaValue === '') {
//                 textareaField.closest('.form-group').addClass('has-error');
//             }
//
//             var hasErrors = form.find('.has-error').length > 0;
//
//             if (!hasErrors) {
//                 this.submit();
//             }
//         });
//     });
// }


function marqueeText() {
    const elements = document.querySelectorAll('.marquee-text');

    elements.forEach(element => {
        const textWidth = element.clientWidth;
        const containerWidth = element.parentNode.offsetWidth;

        const speed = (textWidth / containerWidth) * 15;
        element.style.animation = `scroll ${speed}s linear infinite`;
    });
}

$( document ).ready( function() {
    headerFixed();
    telMask();
    toNextSection();
    mobMenu();
	iconSlider();
    // formValidation();
    marqueeText();

	$( '.banner-box h1, .banner-box .h1' ).each( function (){
		let el = $( this );
		let text = $( this ).text().split( ' ' );
		for (let i = 0; i < text.length; i++) {
			if (text[i].length > 10) {
				el.addClass('long');
				break;
			}
		}
		// if ($( this ).text().length > 12) {
		// 	if ( $( this ).text().indexOf( ' ' ) > 12 || $( this ).text().indexOf( ' ' ) === -1 ) {
		// 		$( this ).addClass( 'long' );
		// 	}
		// }
	});
});

$(window).scroll(function () {
    headerFixed();
});


$( 'body' ).on( 'click', '[href^="#"], [href*="#"]', function ( e ) {
	e.preventDefault();
	let target = $(this).attr( 'href' );
	let nextSection = $( target + '' );
	console.log(target);
	if (nextSection.length) {
		$('html,body').animate({ scrollTop: nextSection.offset().top }, 800);
		return false;
	}
});
